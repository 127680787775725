import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../layouts/default"

import Breadcrumb from "../components/breadcrumb"
import Badge from "../components/badge"
import Button from "../components/button"
import Circle from "../components/circle"
import Column from "../components/column"
import Columns from "../components/columns"
import Paragraph from "../components/paragraph"
import Grid from "../components/grid"
import Heading from "../components/heading"
import Image from "../components/image"
import Inline from "../components/inline"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"
import Text from "../components/text"
import TextLink from "../components/textlink"

const Station = ({ number, title }) => {
  return (
    <Columns alignY="center">
      <Column width="content">
        <Circle size="sm">{number}</Circle>
      </Column>
      <Column>
        <Paragraph>{title}</Paragraph>
      </Column>
    </Columns>
  )
}

const Literaturpfad = () => {
  const data = useStaticQuery(graphql`
    query LiteraturpfadQuery {
      teaser: file(relativePath: { eq: "literaturpfad/teaser.jpg" }) {
        ...largeImage
      }
      buch: file(relativePath: { eq: "literaturpfad/buch.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Tübinger Literaturpfad"
        description="Nirgendwo sonst rückt die europäische Literatur- und Geistesgeschichte so dicht zusammen wie in den engen Häuserreihen der Tübinger Altstadt. Mit dem Tübinger Literaturpfad können Sie die literarischen Orte der Stadt erkunden – digital mit der Literaturpfad-App oder analog mit dem dazugehörigen Buch."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Tübinger Literaturpfad",
                link: "/literaturpfad",
              },
            ]}
          />
          <PageTitle>Tübinger Literaturpfad</PageTitle>
          <Paragraph dropcap={true}>
            Mit dem Tübinger Literaturpfad können Sie die literarischen Orte der Stadt erkunden - digital mit unserer Museumsapp 
            oder analog mit dem dazugehörigen Buch, das im Museumsshop erhältlich ist.
          </Paragraph>
        </Stack>
        <Stack>
          <Paragraph>
            Ausgehend vom Hölderlinturm verknüpft der Literaturpfad 40 literarische Orte in der ganzen Stadt. 
            Plaketten an den Gebäuden weisen auf die Stationen hin. Mit der Museumsapp kann man dieses große literarische Erbe, 
            das sich auf kleinstem Raum erstreckt, erwandern und den Versen der Tübinger Dichterinnen und Dichter lauschen. 
            Auch gibt es eine eigene Tour zu den Hölderlin-Orten der Stadt
          </Paragraph>
          <Grid columns={[1, 2]}>
            <Image
              image={data.teaser.childImageSharp.gatsbyImageData}
              alt="Literaturpfad App auf einem Telefon. Im Hintergrund ist verschwommen einer der Stationen."
            />
            <Stack space={6}>
              <Heading as="span" level={5}>
                Download der Museum Hölderlinturm App
              </Heading>
              <Inline space={3}>
                <Button href="https://play.google.com/store/apps/details?id=de.linon.ida.mht&hl=de">
                  Google Play Store
                </Button>
                <Button href="https://apps.apple.com/de/app/museum-h%C3%B6lderlinturm/id6504112131">
                  App Store
                </Button>
              </Inline>
              <Text color="whisper">
                Wer die App nicht nutzen kann oder will, kann im Hölderlinturm
                kostenlos einen Media-Guide zum Literaturpfad ausleihen.
              </Text>
            </Stack>
          </Grid>
        </Stack>
        <Stack>
          <Heading as="h2" level={3}>
            Literaturpfad-Buch
          </Heading>
          <Grid columns={[1, 2]}>
            <Paragraph>
              Das Buch zum Literaturpfad enthält alle 40 Stationen mit Texten,
              Bildern und einem dazugehörigen Stadtplan. Es ist im Verlag
              Klöpfer, Narr erschienen und auch im{" "}
              <TextLink href="https://www.tuebingen.de/1520.html#/1991">
                städtischen Online-Shop
              </TextLink>{" "}
              erhältlich.
            </Paragraph>
            <Image
              image={data.buch.childImageSharp.gatsbyImageData}
              alt="Literaturpfad Buch aufgeschlagen auf einem Tisch"
            />
          </Grid>
        </Stack>
        <Stack>
          <Heading as="h2" level={3}>
            Literaturpfad-Tour Hölderlin in Tübingen
          </Heading>
          <Paragraph>
            Die zentralen Wegmarken in Hölderlins Biographie liegen in Tübingen
            nur wenige Schritte voneinander entfernt. 13 Jahre nach Abschluss
            seines Studiums am Evangelischen Stift kehrte er zerrüttet an den
            Ort seiner jugendlichen Hoffnungen und Ideale zurück. Er wurde in
            die Universitätsklinik zwangseingewiesen, ein paar Meter vom Stift
            entfernt. Im Haus der Familie Zimmer, das an den Garten der
            Universitätsklinik anschließt, fand er einen Rückzugsort für die
            zweite Hälfte seines Lebens. Die Tour folgt den Spuren Hölderlins in
            Tübingen bis zu seinem Grab auf dem Stadtfriedhof.
          </Paragraph>
          <Inline>
            <Badge>ca. 2,5 km</Badge>
            <Badge>ca. 90 min</Badge>
          </Inline>
          <Heading as="h3" level={4}>
            Stationen
          </Heading>
          <Stack space={6}>
            <Station number="1" title="Hölderlinturm" />
            <Station number="2" title="Burse" />
            <Station number="3" title="Das Evangelische Stift" />
            <Station number="4" title="Ulrichslinde und Schloss" />
            <Station
              number="5"
              title="Peter Härtlings »Denk- und Werkelwohnung«"
            />
            <Station
              number="6"
              title="Martinianum – Keimzelle der Schwäbischen Romantik"
            />
            <Station number="7" title="J. G. Cottasche Verlagsbuchhandlung" />
            <Station number="8" title="Stadtfriedhof" />
            <Station number="9" title="Eduard Mörike in der Walkmühle" />
          </Stack>
          <Button
            href="https://www.tuebingen.de/literaturpfad"
            secondary={true}
          >
            Alle Stationen des Literaturpfads
          </Button>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Literaturpfad
